import { removeAuthToken } from "./token";
import { notification } from "./utility";

export const keycloakLogin = () => {
  const keycloakAuthUrl = process.env.REACT_APP_URL_KEYCLOAK + "auth";

  // provide config / payload param keycloak
  const params = {
    response_type: "code",
    client_id: process.env.REACT_APP_CLIENT_ID_KEYCLOAK,
    redirect_uri: process.env.REACT_APP_URL_WEB + "keycloak",
    // scope: "openid",
  };

  // define url login keycloak
  const loginUrl = `${keycloakAuthUrl}?${new URLSearchParams(params)}`;

  // redirect url login keycloak
  window.location.href = loginUrl;
};

export const generateToken = async (keycloakToken: string) => {
  const platformKey = localStorage.getItem("platform-key");
  const saasUrl =
    process.env.REACT_APP_BASE_URL_SASS + `access/v3/generate/token/keycloak`;
  const resp: any = await fetch(saasUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${keycloakToken}`,
      Platformkey: platformKey,
    },
  });
  const json = await resp.json();
  return json;
};

export const handleLoginResponse = async () => {
  const keycloakTokenUrl = process.env.REACT_APP_URL_KEYCLOAK + `token`;
  const platformKey = localStorage.getItem("platform-key");
  const saasUrl =
    process.env.REACT_APP_BASE_URL_SASS + `access/v3/generate/token/keycloak`;

  const code = new URLSearchParams(window.location.search).get("code");
  if (!code) return;

  try {
    const keycloakResponse = await fetch(keycloakTokenUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_CLIENT_ID_KEYCLOAK,
        client_secret: process.env.REACT_APP_CLIENT_SECRET_KEYCLOAK,
        redirect_uri: process.env.REACT_APP_URL_WEB + "keycloak",
        code,
      }),
    });

    const keycloakData = await keycloakResponse.json();
    console.log(keycloakData);

    if (keycloakData.error) {
      notification("error", keycloakData.error);
      setTimeout(() => {
        window.close();
        window.opener.location.href =
          process.env.REACT_APP_URL_WEB + "login-keycloak?session=true";
      }, 2000);
    }

    localStorage.setItem("token_keycloak", keycloakData.access_token);
    localStorage.setItem("refresh_token", keycloakData.refresh_token);

    const saasResponse = await fetch(saasUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${keycloakData.access_token}`,
        Platformkey: platformKey,
      },
    });

    const saasData = await saasResponse.json();

    if (saasData.StatusCode !== 200) {
      notification("error", saasData.Message);
      setTimeout(() => {
        keycloakLogout(true);
      }, 2000);
    }

    localStorage.setItem("token_sass", saasData.Data.Token);
    localStorage.setItem("refresh_token_sass", saasData.Data.RefreshToken);

    setTimeout(() => {
      window.close();
      window.opener.location.href =
        process.env.REACT_APP_URL_WEB + "login-keycloak?session=true";
    }, 200);
  } catch (error) {
    console.error("Error fetching access token:", error);
  }
};

export const refreshKeycloakToken = () => {
  const keycloakTokenUrl = process.env.REACT_APP_URL_KEYCLOAK + "token";

  fetch(keycloakTokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_CLIENT_ID_KEYCLOAK,
      client_secret: process.env.REACT_APP_CLIENT_SECRET_KEYCLOAK,
      refresh_token: localStorage.getItem("refresh_token"),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      // set new token
      if (!data.error) {
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("token_keycloak", data.access_token);
        notification(
          "info",
          "Refresh token, the browser will refresh automatically"
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        notification("error", "Invalid Token Access");
        setTimeout(() => {
          removeAuthToken();
          window.location.replace("/");
        }, 3000);
      }
    })
    .catch((error) => {
      console.error("Error fetching access token:", error);
    });
};

export const keycloakLogout = (windowPopup: boolean) => {
  const keycloakLogoutUrl = process.env.REACT_APP_URL_KEYCLOAK + "logout";

  fetch(keycloakLogoutUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENT_ID_KEYCLOAK,
      client_secret: process.env.REACT_APP_CLIENT_SECRET_KEYCLOAK,
      refresh_token: localStorage.getItem("refresh_token"), // (optional) include the user's refresh token
    }),
  })
    .then((response) => {
      if (response.status === 204) {
        // success logout
      } else {
        // failed logout, show response message
        console.error("Logout gagal:", response.status);
      }

      setTimeout(() => {
        removeAuthToken();
        if (windowPopup) {
          window.close();
          window.opener.location.href = "/";
        } else {
          window.location.replace("/");
        }
      }, 200);
    })
    .catch((error) => {
      console.error("Error during logout:", error);
    });
};
