import React from "react";
import { Redirect, Route } from "react-router-dom";

function PublicRoute({ component: Component, ...rest }) {
  const isLogin = localStorage.getItem("token_sass");
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin || isLogin === undefined || isLogin === "undefined" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

export default PublicRoute;
