import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum AdditionalFeeType {
  ADDITIONALFEE_PENDING = "ADDITIONALFEE_PENDING",
  ADDITIONALFEE_SUCCESS = "ADDITIONALFEE_SUCCESS",
  ADDITIONALFEE_ERROR = "ADDITIONALFEE_ERROR",
}

export interface IParamAdditionalFee {
  Size?: number;
  Current?: number;
  Status?: string;
  CreatedDate?: string;
  ActiveDate?: string;
  FeeCategory?: string;
  FeeProduct?: string;
  FeeType?: any;
  Keyword?: string;
  IsActiveOnApp?: boolean;
}

export interface IParamAdditionalFeeById {
  Id?: string;
}

export interface IAdditionalFeeDataMaster {
  ActiveDate: string;
  Amount: number;
  ApprovedBy: string;
  ApprovedDate?: string;
  ClientId: number;
  CreatedBy: string;
  CreatedDate: string;
  DeletedBy: string;
  DeletedDate?: string;
  FeeCategory: string;
  FeeProduct: string;
  FeeType: number;
  Id: string;
  IsDeleted: boolean;
  Status: number;
  IsActiveOnApp: boolean;
}

export interface IAdditionalFeeResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface IAdditionalFeeResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: IAdditionalFeeDataMaster;
  Page: {
    CurrentPage: number;
    NextPage: number;
    PrevPage: number;
    TotalPage: number;
    TotalRow: number;
  };
}

export interface IMiddlewareBodyAdditionalFeeIn {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "master-fee";
  Action:
    | "get"
    | "create"
    | "getbyid"
    | "delete"
    | "update"
    | "action-approval";
  ResourceType: "data";
  Resource: "data";
  ResourceId: "data";
  UserId: string;
  Date: string;
  ScopePlatform: "private";
  Platform: "qoin-apps";
  Data:
    | IParamAdditionalFee
    | IParamCreate
    | IParamAdditionalFeeById
    | IParamApprovalAdditionalFee;
}

export interface IParamApprovalAdditionalFee {
  Action: 1 | 2;
  MasterFeeId: string;
}

export interface IParamCreate {
  FeeCategory: string;
  FeeProduct: string;
  FeeType: number;
  Amount: number;
  ActiveDate: string;
}

export interface IMiddlewareBodyAdditionalFeeOut {
  TicketId: string;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "master-fee";
  Action:
    | "get"
    | "getbyid"
    | "create"
    | "delete"
    | "update"
    | "action-approval";
}

export interface IMiddlewareResponseAdditionalFeeOut {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "list";
  Action: "get";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IAdditionalFeeResponseMaster | any;
  Status?: string;
  StatusCode?: number;
}

interface GetAdditionalFeeAction {
  type: AdditionalFeeType.ADDITIONALFEE_SUCCESS;
  payload: {
    data: IMiddlewareResponseAdditionalFeeOut | IMiddleResponse | null;
  };
}

interface SetLoadingAdditionalFeeAction {
  type: AdditionalFeeType.ADDITIONALFEE_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorAdditionalFeeAction {
  type: AdditionalFeeType.ADDITIONALFEE_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IAdditionalFeeState {
  data: IMiddlewareResponseAdditionalFeeOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  isLoadingDetail: boolean;
  dataExport: any;
  isLoadingExport: boolean;
  isLoadingDDl: boolean;
  isLoadingCreate: boolean;
  isLoadingDelete: boolean;
  isLoadingApproval: boolean;
}

export type AdditionalFeeAction =
  | GetAdditionalFeeAction
  | SetLoadingAdditionalFeeAction
  | SetErrorAdditionalFeeAction;
