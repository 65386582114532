import classNames from "classnames";
import React, { useState } from "react";
import Dropdown from "../Dropdown";
import { IDropdown } from "../Dropdown/interface";
import { IInputSelect } from "./interface";

import "./input.scss";
import { ICON_CLOSE } from "../../../assets/icon2";
import Select from "react-select";
import { InputActionMeta } from "react-select";

interface ICombination extends IInputSelect, IDropdown {
  disable?: boolean;
}

const Component: React.FC<ICombination> = ({
  className,
  onChange,
  value,
  error,
  style,
  name,
  data,
  filterText,
  setValue,
  isClearable,
  disable,
  ...prop
}) => {
  let clearable = isClearable === false ? false : true;

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const handleFocus = () => {
    setShowDropDown(true);
  };
  const handleBlur = () => {
    setTimeout(() => {
      setShowDropDown(false);
    }, 100);
  };
  console.log({ value });
  console.log({ data });

  const getValue = (value: any) => {
    const getData = data.find((dataSel: any) => dataSel.value === value);
    if (getData && value) {
      return [{ value: getData.value, label: getData.name }];
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="input-comp ">
        <div className="d-flex">
          <Select
            name="colors"
            options={
              data?.map((x: any) => ({
                value: x.value,
                label: x.name,
              })) || []
            }
            className="basic-multi-select w-100"
            classNamePrefix="select"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                // primary25: 'hotpink',
                primary: "grey",
              },
            })}
            value={getValue(value)}
            onChange={(e) => {
              onChange(e.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Component;
