import React from "react";
import DatePicker from "react-datepicker";
import { IInputDate } from "./interface";

import "./input.scss";

const Component: React.FC<IInputDate> = ({ startDate, endDate, onChange }) => {
  return (
    <>
      <div className="w-100">
        <DatePicker
          className="date-pick"
          selected={startDate || null}
          onChange={onChange}
          startDate={startDate || null}
          endDate={endDate || null}
          selectsRange
          placeholderText="Select Date Range"
          isClearable={true}
          disabledKeyboardNavigation
        />
      </div>
    </>
  );
};

export default Component;
