import axios, { AxiosRequestConfig } from "axios";
import { getCookieFromBrowser } from "../utils/cookie";
import { getAuthToken, getAuthTokenSass } from "../utils/token";
import { encodeGetAccount } from "../utils/utility";
import { refreshKeycloakToken } from "../utils/keycloak-auth";

const token = localStorage.getItem("token_sass");

const platformKey = localStorage.getItem("platform-key");

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};

if (token) {
  Object.assign(headers, { Authorization: `Bearer ${token}` });
}

if (platformKey) {
  Object.assign(headers, { Platformkey: platformKey });
}

const isTokenExpired = () => {
  const decodedToken = encodeGetAccount(token);
  return decodedToken.exp * 1000 < Date.now();
};

const instances = axios.create({
  timeout: 1000000,
  headers,
});

function onFulFilledRequest(conf: AxiosRequestConfig) {
  return conf;
}

function onRejectedRequest(err: any): Promise<any> {
  return Promise.reject(err);
}

function onFulFilledResponse(res: any): Promise<any> {
  return Promise.resolve(res);
}

function onRejectedResponse(err: any) {
  const response = err.response;

  if (response?.status === 401 && response?.data?.message === "invalid token") {
    localStorage.clear();
    window.location.replace("/login");

    return Promise.reject(err);
  }

  return Promise.reject(err);
}
instances.interceptors.request.use(
  async (conf: AxiosRequestConfig) => {
    if (token && isTokenExpired()) {
      await refreshKeycloakToken();
    } else {
      return onFulFilledRequest(conf);
    }
  },
  (err: any) => {
    return onRejectedRequest(err);
  }
);
instances.interceptors.response.use(onFulFilledResponse, onRejectedResponse);

export default instances;
