import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import "moment/locale/id";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { store } from "./redux";
import App from "./App";
import Loading from "./utils/Loading";
import "bootstrap/dist/css/bootstrap.min.css";

moment.locale("id");

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={Loading()} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}

      <App />

      {/* </PersistGate> */}
    </Provider>
  </CacheBuster>,
  // </ReactKeycloakProvider>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
